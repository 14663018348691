'use client';

import React, { useState } from 'react';
import useSWR from 'swr';
import { AlertDocument, AnnouncementDocument } from '@/prismicio-types';
import { useLang } from '@/hooks/useLang';
import AlertBar from '@/components/atoms/AlertBar';
import { ALERT_REFETCH_INTERVAL } from './consts';
import { getAlert, getAnnouncement } from './utils';
const AlertsAndAnnouncements = () => {
  const [showAlert, setShowAlert] = useState(true);
  const [showAnnouncement, setShowAnnouncement] = useState(true);
  const lang = useLang();
  const {
    data: alert
  } = useSWR<AlertDocument['data']>(['alert', lang], () => getAlert(lang), {
    refreshInterval: ALERT_REFETCH_INTERVAL
  });
  const {
    data: announcement
  } = useSWR<AnnouncementDocument['data']>(['announcement', lang], () => getAnnouncement(lang));
  return <div data-sentry-component="AlertsAndAnnouncements" data-sentry-source-file="index.tsx">
      {showAlert && alert && <div className="animate-fade-in">
          <AlertBar variant="alert" content={alert.content} onClose={() => setShowAlert(false)} />
        </div>}
      {showAnnouncement && announcement && <div className="animate-fade-in">
          <AlertBar variant="announcement" content={announcement.content} onClose={() => setShowAnnouncement(false)} />
        </div>}
    </div>;
};
export default AlertsAndAnnouncements;