import React from 'react';
import clsx from 'clsx';
import { CloseIcon } from '@/public/assets/svg';
import RichText from '../RichText';
import { AlertBarProps } from './types';
const AlertBar: React.FC<AlertBarProps> = ({
  content,
  variant,
  onClose
}) => <div className={clsx('relative left-[calc(50%_-_4px)] right-1/2 -ml-[50vw] flex w-screen max-w-[100vw] px-8 py-3 text-center', {
  'bg-primary-500': variant === 'announcement',
  'bg-primary-650': variant === 'alert'
})} data-sentry-component="AlertBar" data-sentry-source-file="index.tsx">
    <div className="flex-1">
      <RichText field={content} data-sentry-element="RichText" data-sentry-source-file="index.tsx" />
    </div>
    <button className="group" type="button" onClick={onClose}>
      <CloseIcon className="transition-transform group-hover:scale-110" data-sentry-element="CloseIcon" data-sentry-source-file="index.tsx" />
    </button>
  </div>;
export default AlertBar;