import { ROUTES } from '@/routes';
import { Lang } from '@/types/locales';

export const getAlert = async (lang: Lang) => {
  const response = await fetch(ROUTES.alert(lang));

  if (!response.ok) {
    return null;
  }

  const alert = await response.json();

  return alert;
};

export const getAnnouncement = async (lang: Lang) => {
  const response = await fetch(ROUTES.announcement(lang));

  if (!response.ok) {
    return null;
  }

  const alert = await response.json();

  return alert;
};
